import { tipperTooltip, tipperMenu } from './Tipper.module.scss';
// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    TIPPER_MAIN_CONTAINER: 'tipper_main_container',
    TIPPER_CONTENT: 'tipper_content',
    TIPPER_TARGET: 'tipper_target',
    TIPPER_CLOSE_BUTTON: 'tipper_close_button'
};
export const listPlacement = {
    bottom_start: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'bottom-start',
    },
    bottom_end: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'bottom-end',
    },
    bottom: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'bottom',
    },
    top_start: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'top-start',
    },
    top_end: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'top-end',
    },
    top: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'top',
    },
    left_start: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'left-start',
    },
    left_end: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'left-end',
    },
    left: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'left',
    },
    right_start: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'right-start',
    },
    right_end: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'right-end',
    },
    right: {
        styleTooltip: `${tipperTooltip}`,
        styleMenu: `${tipperMenu}`,
        placement: 'right',
    },
};
