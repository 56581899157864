// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    BREADCRUMB_CONTAINER: 'breadcrumb_container',
    BREADCRUMB_ELEMENT: 'breadcrumb_element',
    BREADCRUMB_POSITION: 'breadcrumb_position',
    BREADCRUMB_TITLE: 'breadcrumb_title',
};
export const sampleAttributes = {
    breadcrumbs: [
        {
            id: 10802,
            term_id: null,
            text: 'Home',
            url: 'https://dev.lowermybills.com/',
        },
        {
            id: 11802,
            term_id: null,
            text: 'Personal Loans',
            url: 'https://dev.lowermybills.com/personal-loans/',
        },

        {
            id: 14984,
            term_id: null,
            text: 'How To Get A Loan With Bad Credit',
            url:
                'https://dev-lmb.pantheonsite.io/personal-loans/how-to-get-a-loan-with-bad-credit/',
        },
    ],
};
