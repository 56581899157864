import React from 'react';
import PropTypes from 'prop-types';
import getHomeUrl from '../../../utilities/getHomeUrl'

import { testAttributes } from './Constants';
import Linker from '../Linker';
import { listBreadcrumb, breadcrumbElement } from './Breadcrumb.module.scss';

const Breadcrumb = ({ breadcrumbs, homeUrl }) => (
    <ul
        className={listBreadcrumb}
        data-test={testAttributes.BREADCRUMB_CONTAINER}
        id="breadcrumb"
        itemScope
        itemType="https://schema.org/BreadcrumbList"
    >
        {breadcrumbs?.map((node, index) => (
            <li
                className={breadcrumbElement}
                data-test={testAttributes.BREADCRUMB_ELEMENT}
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
                key={node.id}
            >
                <Linker href={ index === 0 ? homeUrl : node.url } relative={false} itemProp="item">
                    <span
                        data-test={testAttributes.BREADCRUMB_TITLE}
                        itemProp="name"
                    >
                        {node.text}
                    </span>
                </Linker>
                <meta
                    content={index + 1}
                    data-test={testAttributes.BREADCRUMB_POSITION}
                    itemProp="position"
                />
            </li>
        ))}
    </ul>
);
Breadcrumb.propTypes = {
    /** Breadcrumbs Array */
    breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
    /** HomeUrl for linker 0 string */
    homeUrl: PropTypes.string,
};
Breadcrumb.defaultProps = {
    homeUrl: getHomeUrl(),
}


export default Breadcrumb;
