import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import parseHTML from '../../../utilities/parseHTML';
import { noteNumber, textFootNote, tooltipText } from './Footnote.module.scss';
import { testAttributes, articleSourcesId } from './Constants';
import handleSmooth from '../../../utilities/handleSmooth';

export const scrollTo = () => 
{
    handleSmooth(
        `#${articleSourcesId} ~ [aria-expanded=false]`,
        `#${articleSourcesId} > div`
    );
};
const Footnote = ({ number, content }) => 
{
    const fnValidateContent = () =>
        typeof content === 'string' ? parseHTML(content) : content;

    return (
        <span
            className={textFootNote}
            data-test={testAttributes.FOOTNOTE_COMPONENT}
        >
            <Tooltip
                data-test={testAttributes.FOOTNOTE_TOOLTIP}
                placement="bottom"
                theme="WithArrow"
                content={
                    <div className={tooltipText}>{fnValidateContent()}</div>
                }
                trigger="mouseenter"
            >
                <button
                    type="button"
                    onClick={scrollTo}
                    data-test={testAttributes.FOOTNOTE_TOOLTIP_BUTTON}
                    className={noteNumber}
                >
                    [{`${number}`}]
                </button>
            </Tooltip>
        </span>
    );
};

Footnote.propTypes = {
    /** Number of Footnote */
    number: PropTypes.number.isRequired,
    /** Tooltip content ( HTML) */
    content: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
        PropTypes.array,
    ]).isRequired,
};

export default Footnote;
