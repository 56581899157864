import HTMLReactParser from 'html-react-parser';
import parseHTML from '../../parseHTML';

const defaultParser = HTMLReactParser.default;

/**
 * 
 * @param {string} content : string with html to convert in react components 
 * @param {function[]} listReplacements : receive array of functions with the following structure { 
 *   some attributes necessary for each parse functions 
 *   must return a object with the structure {
 *     fn: (function parse) , 
 *     callback : ( function callback inject such as param to function parse ) ,
 *     return :  ( object optional to return attributes from function)  
 *   }
 * }
 * @returns  array[result,parsedContent] : result = instance of the function receive in listReplacements , parsedContent = content convert in react components 
 */
const parseWith = (  content ,  listReplacements) =>
{
    const result = {};
    const listParseFn = Object.keys(listReplacements).map(replacement=>[replacement,listReplacements[replacement]()])
    const allowReplacements = listParseFn.map(replacement=> replacement[1].fn(replacement[1].callback))
    listParseFn.forEach(replacement=>
    {
        const [index,value] = replacement
        result[index]=value
        return null;
    })

    const addParser = (sanitized, options) =>
        defaultParser(sanitized, {            
            replace: options.addReplacements(allowReplacements),
        });
        
    const parsedContent = parseHTML( content, addParser );  
    return [ result, parsedContent  ];   
}
export default parseWith;