import footnoteParser from '../../../utilities/parses/parseWith/footnoteParser';
/**
 * in this file create all functions to pass by params to the function parseWith 
 * this parse function must return a object with the attributes 
 *   fn = function of parse in src\utilities\parses\parseWith ,
 *   callback = function callback to past by param to fn ( function parse ) 
 *   return = (optional) can create a new attribute to get attributes crated in the function
 * @returns 
 */
// eslint-disable-next-line import/prefer-default-export
export const fnFootnoteParser = () => 
{
    let listArticles=[]
    return {
        fn : footnoteParser ,
        callback : (source) => 
        {
            listArticles = [...listArticles, source];               
            return listArticles.length;
        },
        return: {
            articleSourceList: () => listArticles 
        }
    }        
}
