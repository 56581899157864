/* eslint-disable react/prop-types */
import React from 'react';
import { domToReact } from 'html-react-parser';
import Footnote from '../../../../components/composite/Footnote';
import { wpFootnoteClass } from '../Constants';
/**
 * return a component Footnote with classname is wpFootnoteClass
 * @param {*} callback : function receive by param to run custom functionality where the function is called 
 * @returns a componente Footnote
 */
const footnoteParser = (callback) => ({ name, type, attribs, children }) => 
{
    if (type !== 'tag') return null;
    if (!name || name !== 'span') return null;
    const classNames = attribs?.class?.split(' ') || [];
    if (!classNames.some((className) => className === wpFootnoteClass))
        return null;
    const footnoteContent = domToReact(children);
    const footnoteIndex = callback(footnoteContent);
    return <Footnote content={footnoteContent} number={footnoteIndex} />;
};

export default footnoteParser;
