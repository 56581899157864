import * as buildEnvs from '../../../_envs.json';

const defaultHomeUrl = 'https://www.lowermybills.com/'

const getHomeUrl = () =>
{
    const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'local';
    let siteHomeUrl =
        process.env.GATSBY_SITE_URL ||
        (buildEnvs[activeEnv]
            ? buildEnvs[activeEnv].GATSBY_SITE_URL
            : defaultHomeUrl );
    siteHomeUrl = siteHomeUrl.endsWith('/') ? siteHomeUrl : `${siteHomeUrl}/`;
    return siteHomeUrl;

}

export default getHomeUrl;
export { defaultHomeUrl };