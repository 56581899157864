import React from 'react';
import PropTypes from 'prop-types';
import Tipper from '../../simple/Tipper';
import { testAttributes } from './Constants';
import { tooltipMain } from './Tooltip.module.scss';

export const listPlacement = [
    'bottom_start',
    'bottom_end',
    'bottom',
    'top_start',
    'top_end',
    'top',
    'left_start',
    'left_end',
    'left',
    'right_start',
    'right_end',
    'right',
];

export const listThemes = ['WithArrow', 'WithoutArrow'];

export const listTrigger = ['mouseenter', 'click'];

const Tooltip = ({
    placement,
    theme,
    content,
    children,
    trigger,
    showTooltip,
    hasCloseButton,
}) => 
{
    const tippyProps = (modeTrigger) => 
    {
        const conf = {
            interactive: true,
            animation: 'scale',
            offset: [0, 15],
            appendTo: 'parent',
            maxWidth: 350,
            trigger: modeTrigger,
            delay: [300, 0],
            aria: {
                expanded: false,
            },
        };

        return conf;
    };

    return (
        <span
            data-test={testAttributes.TOOLTIP_MAIN_CONTAINER}
            className={tooltipMain}
        >
            <Tipper
                placement={placement}
                content={content}
                configuration={tippyProps(trigger)}
                type="tooltip"
                theme={theme}
                disabled={!showTooltip}
                hasCloseButton={hasCloseButton}
            >
                <span>{children}</span>
            </Tipper>
        </span>
    );
};

Tooltip.propTypes = {
    /** Placement, place where is showing the tooltip */
    placement: PropTypes.oneOf(listPlacement).isRequired,
    /** Theme, On/off the Arrow */
    theme: PropTypes.oneOf(listThemes).isRequired,
    /** Content, Component is inside in the body of Tooltip */
    content: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
    ]).isRequired,
    /** Children, Component trigger the Tooltip */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    trigger: PropTypes.oneOf(listTrigger),
    showTooltip: PropTypes.bool,
    /** hasCloseButton: whether a clickable close button [X] is rendered */
    hasCloseButton: PropTypes.bool,
};

Tooltip.defaultProps = {
    children: '',
    trigger: 'mouseenter',
    showTooltip: true,
    hasCloseButton: false,
};

export default Tooltip;
