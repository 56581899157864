import React from 'react';
import Tippy from '@tippyjs/react';
import { hideAll } from 'tippy.js';
import PropTypes from 'prop-types';
import BlockButton from '../../mortgage/BlockButton'

import { testAttributes, listPlacement } from './Constants';

import { TipperContent } from './Tipper.module.scss';

export const placements = Object.keys(listPlacement);

export const listTypes = ['tooltip', 'menu'];

export const fnGetStyle = (type, placement) =>
    type === 'tooltip'
        ? listPlacement[placement].styleTooltip
        : listPlacement[placement].styleMenu;

export const fnGetExtraConfiguration = (
    type,
    place,
    content,
    theme,
    disabled,
    closeButtonFn
) => ({
    content: (
        <div
            className={fnGetStyle(type, place)}
            data-test={testAttributes.TIPPER_CONTENT}
        >
            {content}
            { closeButtonFn &&
                <span className="closeButton" data-test={testAttributes.TIPPER_CLOSE_BUTTON}><BlockButton submit={false} onClick={closeButtonFn} label="&times;" /></span>
            }
        </div>
    ),
    placement: listPlacement[place].placement,
    onShow: () => hideAll(),
    theme,
    disabled,

});

const Tipper = ({
    configuration,
    children,
    content,
    placement,
    type,
    theme,
    disabled,
    hasCloseButton
}) => 
{
    let tippyInstance = null;
    const doHide = () => 
    {
        tippyInstance?.hide();
        tippyInstance = null;
    };
    const containerContent = fnGetExtraConfiguration(
        type,
        placement,
        content,
        theme,
        disabled,
        hasCloseButton ? doHide : null
    );
    const eventConfigs = {
        onTrigger: (instance) =>
        {
            tippyInstance = instance;
        }
    }
    return (
        <span
            className={TipperContent}
            data-test={testAttributes.TIPPER_MAIN_CONTAINER}
        >
            <Tippy {...{ ...configuration, ...containerContent, ...eventConfigs }}>
                <span data-test={testAttributes.TIPPER_TARGET}>{children}</span>
            </Tippy>
        </span>
    );
};

Tipper.propTypes = {
    /** configuration object for TippyJS */
    configuration: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
        .isRequired,
    /** content of popup tooltip */
    content: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
    ]),
    /** visible element over which user hovers to trigger the tooltip */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /** one of a list of placements, e.g. 'bottom', 'bottom-left' etc. */
    placement: PropTypes.oneOf(placements).isRequired,
    /** type s/b 'tooltip' */
    type: PropTypes.oneOf(listTypes).isRequired,
    /** theme, from vendor: s/b 'WithArrow' */
    theme: PropTypes.string.isRequired,
    /** disabled - active or hidden */
    disabled: PropTypes.bool,
    /** hasCloseButton: whether a clickable close button [X] is rendered */
    hasCloseButton: PropTypes.bool
};

Tipper.defaultProps = {
    children: '',
    content: '',
    disabled: false,
    hasCloseButton: false
};

export default Tipper;
